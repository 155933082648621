import React from "react";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import UpComingProp from "./itemProp/UpComingProp";

const UpComingGridView = () => {
  return (
    <>
      <SEO title="Up Coming" />
      <Layout>
        <div className="main-content">
          {/* Start Blog Area  */}
          <div className="rn-blog-area rn-section-gap">
            <div className="container">
              <div className="row mt_dec--30">
                <UpComingProp
                  column="col-lg-4 col-md-6 col-12 mt--30"
                  StyleVarProp="box-card-style-default"
                />
              </div>
            </div>
          </div>
          {/* End Blog Area  */}
        </div>
      </Layout>
    </>
  );
};
export default UpComingGridView;
