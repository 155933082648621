import React from "react";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import SuccessHistoryProp from "./itemProp/SuccessHistoryProp";

const SuccessHistoryGridView = () => {
  return (
    <>
      <SEO title="Success History" />
      <Layout>
        <div className="main-content">
          {/* Start Our History  */}
          <div className="rn-blog-area rn-section-gap">
            <div className="container">
              <div className="row mt_dec--30">
                <SuccessHistoryProp
                  column="col-lg-4 col-md-6 col-12 mt--30"
                  StyleVarProp="box-card-style-default"
                />
              </div>
            </div>
          </div>
          {/* End Our History  */}
        </div>
      </Layout>
    </>
  );
};
export default SuccessHistoryGridView;
