import PropTypes from "prop-types";
import React from 'react'


const SEO = ( {title} ) => {
    return (
        <>
            <meta charSet="utf-8" />
            <title>{title} || iCAN - Cadet & ISSB Academy</title>
            <meta name="robots" content="index, follow" />
            {/* <meta name="description" content="iCAN - cadet and issb coaching academy" /> */}
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        </>
    )
}
SEO.propTypes = {
    title: PropTypes.string
};

export default SEO;


