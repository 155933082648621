import React, { useState, useEffect } from "react";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../breadcrumb/BreadcrumbOne";
import SectionTitle from "../sectionTitle/SectionTitle";
import GalleryOne from "./GalleryOne";
import Axios from "axios";

const Elements = () => {
  const [galleries, setGalleries] = useState([]);

  const getAllGalleries = async () => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    const getSliders = async () => {
      const res = await Axios.get(
        `${process.env.REACT_APP_API_URL}/galleries/list`,
        {
          signal,
        }
      );

      if (res.status === 404) {
        setGalleries([]);
      } else {
        setGalleries(res.data.data);
      }
    };
    getSliders();
    return () => {
      abortController.abort();
    };
  };

  useEffect(() => {
    getAllGalleries();
  }, []);

  return (
    <>
      <SEO title="Gallery" />
      <Layout>
        {/* <BreadcrumbOne 
                    title=""
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Gallery"
                /> */}
        <div className="main-content">
          {/* Start Elements Area  */}
          <div className="rwt-gallery-area rn-section-gap">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 mb--20">
                  <SectionTitle
                    textAlign="text-center"
                    radiusRounded=""
                    subtitle=""
                    title="Gallery"
                    description=""
                  />
                </div>
              </div>
              <div className="row mt_dec--30 row--15">
                {galleries.map((item) => (
                  <div
                    className="col-lg-4 col-md-4 col-sm-4 col-12 mt--30"
                    key={item.id}
                  >
                    <GalleryOne galleryItem={item} />
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* End Elements Area  */}
        </div>
      </Layout>
    </>
  );
};
export default Elements;
